article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.visuallyhidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0);
  overflow: hidden; }

@font-face {
  font-family: 'Merriweather';
  src: url("../fonts/merriweather-black-webfont.woff2") format("woff2"), url("../fonts/merriweather-black-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Merriweather';
  src: url("../fonts/merriweather-regular-webfont.woff2") format("woff2"), url("../fonts/merriweather-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../fonts/opensans-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-bolditalic-webfont.woff2") format("woff2"), url("../fonts/opensans-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-extrabold-webfont.woff2") format("woff2"), url("../fonts/opensans-extrabold-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-light-webfont.woff2") format("woff2"), url("../fonts/opensans-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-semibold-webfont.woff2") format("woff2"), url("../fonts/opensans-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/opensans-semibolditalic-webfont.woff2") format("woff2"), url("../fonts/opensans-semibolditalic-webfont.woff") format("woff");
  font-weight: 600;
  font-style: italic; }

html {
  font-family: "Open Sans", "open sans", "sans serif";
  color: #586162; }

body {
  position: relative; }

.no-js {
  visibility: hidden;
  opacity: 0; }

.js {
  visibility: visible;
  opacity: 1; }

.wrapper {
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  z-index: 20; }

.ui-loader {
  display: none; }

ul {
  padding: 0;
  list-style: none; }

h2.blueLine {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 28px;
  display: inline-block; }
  h2.blueLine:after {
    width: 44px;
    height: 8px;
    left: auto;
    right: 0; }
  h2.blueLine:hover:after {
    height: 8px; }

a {
  text-decoration: none;
  transition: all 0.5s; }
  a:focus {
    outline: none; }

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px; }

section {
  padding: 120px 0;
  overflow: hidden; }

.italic {
  font-style: italic; }

.hideOverlay {
  display: none; }

.overflow {
  overflow: hidden; }

.blueLine {
  position: relative;
  z-index: 2; }
  .blueLine:after {
    content: '';
    display: inline-block;
    background-color: #6DEAEA;
    height: 4px;
    width: calc(100% + 2px);
    z-index: -1;
    transform-origin: center bottom;
    transition: all 0.2s ease-in-out 0s;
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    will-change: height; }
  .blueLine:hover:after {
    height: 15px; }
  .blueLine img {
    height: 17px;
    transform: rotate(-90deg) translateX(-4px) translateY(10px);
    width: auto; }

.moreLink {
  font-size: 16px;
  text-transform: uppercase;
  color: #586162;
  letter-spacing: 0.5px;
  font-weight: 700; }

.--light {
  font-weight: 300; }

.semiBold {
  font-weight: 600; }

.--bold {
  font-weight: 700; }

.--extraBold {
  font-weight: 900; }

@media (max-width: 1028px) {
  .wrapper {
    width: 90%; } }

@media (max-width: 760px) {
  section {
    padding: 100px 0; }
  p {
    font-size: 20px;
    line-height: 30px; }
  .blueLine:after {
    bottom: -8px;
    height: 8px; }
  .blueLine:hover:after {
    height: 22px; }
  h2.blueLine {
    font-size: 36px; }
    h2.blueLine:after {
      width: 64px;
      height: 15px;
      bottom: -10px; }
    h2.blueLine:hover:after {
      height: 15px; }
  .moreLink.blueLine {
    font-size: 17px;
    font-weight: 900; }
    .moreLink.blueLine:after {
      bottom: -8px;
      height: 8px; }
    .moreLink.blueLine:hover:after {
      height: 22px; } }

header {
  height: 100vh;
  position: relative;
  will-change: background-image;
  background-image: url("../assets/header/header1small.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  z-index: 300; }

.headerMenu {
  height: 150px;
  padding: 30px 0;
  position: fixed;
  z-index: 350;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.5s; }

.menuDesktopScroll {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); }

.hideHeaderMenu {
  transform: translateY(-150px); }

.menuList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0; }

.headerLogo {
  width: 212px; }

.menuLink.blueLine {
  text-transform: uppercase;
  color: white;
  font-weight: 700; }
  .menuLink.blueLine:after {
    width: 100%;
    left: 0; }

.upCircle {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 0; }

.upcomingHeader {
  background-color: white;
  padding: 8px 23px 15px 10vw; }

.upcoming {
  color: #586162;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  display: flex; }
  .upcoming img {
    width: 18px;
    transform: rotate(90deg) translateX(6px);
    margin-left: 5px; }

.upcoming.blueLine:after {
  width: calc(100% + 5px);
  left: -3px; }

.headerCircles {
  display: flex;
  justify-content: space-between;
  width: 92px;
  margin-left: 37px; }

.circle {
  width: 15px;
  height: 15px;
  border: white solid 2px;
  border-radius: 50px;
  transition: all 0.5s; }

.circleFill {
  background-color: white; }

.mobileLogo {
  display: none; }

.mobileButton {
  display: none; }

.mobileMenu {
  display: none; }

@media (max-width: 1028px) {
  .upcomingHeader {
    padding: 8px 23px 15px 5vw; }
  .headerCircles {
    left: 233px; } }

@media (max-width: 860px) {
  header {
    height: 85vh; }
  .headerMenu {
    position: fixed;
    z-index: 350; }
    .headerMenu .menuList {
      display: none; }
  .mobileLogo {
    display: block;
    position: relative;
    height: 100%;
    z-index: 10; }
    .mobileLogo .headerLogo {
      width: 365px;
      transform-origin: 100% 100%;
      position: absolute;
      transform: rotate(-90deg);
      bottom: 433px;
      right: 11vw; }
  .mobileButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 20;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 15px;
    padding-right: 11vw; }
    .mobileButton p {
      color: white;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      margin-top: -7px;
      transition: all 0.3s; }
    .mobileButton:hover .mobileLine {
      background-color: #6DEAEA; }
  .mobileLine {
    width: 43px;
    height: 7px;
    border-radius: 50px;
    margin-bottom: 9px;
    background-color: white;
    transition: all 0.3s; }
  .headerCircles {
    margin-right: 10vw; }
  .upcomingHeader {
    padding: 13px 23px 23px 51px; }
  .upCircle {
    width: 100%;
    justify-content: space-between; }
  .upcoming.blueLine {
    font-size: 17px;
    font-weight: 900; }
    .upcoming.blueLine:after {
      bottom: -9px;
      height: 9px; }
    .upcoming.blueLine:hover:after {
      height: 25px; }
    .upcoming.blueLine img {
      width: 12px;
      transform: rotate(90deg) translateX(5px); }
  .mobileMenu {
    display: block;
    width: 328px;
    height: 100%;
    background-color: #00272C;
    position: fixed;
    right: 0;
    z-index: 499;
    padding: 27px;
    transform-origin: right center;
    transition: all 0.6s;
    transform: scaleX(0);
    overflow: scroll; }
  .openMobileMenu {
    transform: scaleX(1); }
  .menuCloseButton {
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: 57px;
    right: 30px; }
    .menuCloseButton:hover {
      color: #6DEAEA; }
    .menuCloseButton:hover .menuX {
      background-color: #6DEAEA; }
  .menuX {
    width: 46px;
    height: 10px;
    border-radius: 50px;
    background-color: white;
    transition: all 0.3s; }
  .x1 {
    transform: rotate(45deg);
    margin-bottom: -10px; }
  .x2 {
    margin-bottom: 20px;
    transform: rotate(-45deg); }
  .mobileMenuList {
    margin-top: 60px;
    margin-bottom: 51px; }
  .mobileItem {
    margin-bottom: 18px; }
  a.mobileListLink.blueLine {
    font-size: 30px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s; }
    a.mobileListLink.blueLine:hover {
      color: #6DEAEA; }
    a.mobileListLink.blueLine:after {
      width: 34px;
      height: 9px; }
  a.socialMobileLink.blueLine {
    font-size: 23px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    transition: all 0.3s; }
    a.socialMobileLink.blueLine:hover {
      color: #6DEAEA; }
    a.socialMobileLink.blueLine:after {
      background-color: #586162;
      width: 34px;
      height: 6px;
      bottom: -6px; } }

@media (max-width: 860px) and (max-height: 633px) {
  .mobileLogo .headerLogo {
    width: 254px;
    bottom: 315px; } }

@media (max-width: 860px) and (max-height: 400px) {
  .mobileLogo .headerLogo {
    width: 254px;
    bottom: 315px;
    transform: none;
    top: 20px;
    bottom: auto;
    right: auto;
    left: 20px; } }

.bioSection {
  background-color: #F5F4F4; }

.bioContent {
  display: flex; }

.bioQuote {
  width: calc(50% - 75px);
  margin-right: 75px; }

.bioWord {
  position: relative; }
  .bioWord p {
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    position: absolute;
    z-index: 2;
    font-size: 200px;
    margin: 0;
    font-size: 22vw;
    letter-spacing: -18px;
    top: -60px;
    line-height: 1; }

.bioIntro {
  position: relative;
  z-index: 4;
  font-family: "Merriweather";
  margin-left: 63px; }
  .bioIntro p {
    font-weight: 700;
    font-size: 17px; }

.bioText {
  width: calc(50% - 75px);
  margin-left: 75px;
  position: relative;
  z-index: 9; }

.showBio {
  display: none; }

@media (max-width: 900px) {
  .bioQuote {
    margin-right: 30px;
    width: calc(50% - 30px); }
  .bioText {
    margin-left: 30px;
    width: calc(50% - 30px); } }

@media (max-width: 730px) {
  .bioContent {
    flex-direction: column; }
  .bioQuote,
  .bioText {
    margin: 0;
    width: 100%; }
  .bioText {
    margin-top: 61px; }
  .bioIntro {
    margin: 0; }
    .bioIntro p {
      font-size: 20px; }
  .bioWord p {
    left: -10%;
    font-size: 42vw; } }

@media (max-width: 550px) {
  .bioWord p {
    font-size: 62vw; } }

.scheduleSection {
  color: white;
  display: flex;
  padding: 0; }

.scheduleList {
  margin-left: 71px;
  margin-bottom: 50px; }

.scheduleContent {
  padding: 120px 0;
  width: 50%;
  background-image: url("../assets/sched/sched1small.jpg");
  background-size: cover;
  background-repeat: no-repeat; }
  .scheduleContent p {
    margin: 0; }
  .scheduleContent .moreLink {
    margin-left: 71px;
    color: white; }
  .scheduleContent .wrapper {
    width: 39vw; }

.scheduleItem {
  display: flex;
  align-items: center;
  padding: 18px 0 10px 0;
  border-bottom: 1px solid white; }
  .scheduleItem:last-of-type {
    border: none; }
  .scheduleItem a.blueLine {
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700; }
    .scheduleItem a.blueLine:after {
      left: 13px;
      width: 50%; }

.schedDate {
  width: 131px; }

.scheduleContent p.scheduleDate {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  margin-top: 4px; }

.schedDetail {
  margin-right: 0px;
  width: 222px; }

.title {
  font-size: 20px;
  font-style: italic; }

.location {
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
  text-transform: uppercase; }

.onlineP {
  font-size: 11px;
  line-height: 11px;
  font-weight: 400;
  text-transform: uppercase;
  display: block; }

.schedulePic {
  padding: 120px 0;
  width: 50%;
  background-image: url("../assets/sched/sched1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right; }
  .schedulePic img {
    display: none; }

.scheduleOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 800;
  opacity: 0;
  overflow: scroll;
  background-color: white;
  padding: 60px 0;
  transition: all 0.5s; }
  .scheduleOverlay h2.blueLine:after {
    left: 262px; }
  .scheduleOverlay .wrapperBig {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.showOverlay {
  display: block;
  opacity: 1; }

.closeOverlay {
  display: flex;
  font-size: 17px;
  margin: -4px 0 0 0;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 200;
  color: #586162; }
  .closeOverlay:hover {
    color: #6DEAEA; }
  .closeOverlay:hover .line {
    background-color: #6DEAEA; }

.closeX {
  margin-top: 10px;
  margin-left: 10px; }
  .closeX .line {
    width: 20px;
    height: 3px;
    background-color: #586162;
    position: absolute;
    transition: all 0.5s; }
  .closeX .line1 {
    transform: rotate(45deg); }
  .closeX .line2 {
    transform: rotate(-45deg); }

.schedOverlayList {
  margin-top: 47px; }

.overlayItem {
  padding: 31px 0px;
  transition: all 0.5s; }
  .overlayItem:hover {
    color: white;
    background-image: url("../assets/sched/sched1small.jpg");
    background-repeat: no-repeat;
    background-size: cover; }
  .overlayItem:hover a.blueLine {
    color: white; }
  .overlayItem a.blueLine {
    color: #586162;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    transition: all 0.5s;
    min-width: 96px; }
    .overlayItem a.blueLine:after {
      left: 16px;
      width: 50%; }
  .overlayItem p {
    margin: 0; }
  .overlayItem .wrapper {
    display: flex;
    align-items: center;
    padding-right: 21vw; }

.overlayDate {
  min-width: 127px;
  margin-right: 95px;
  text-transform: uppercase; }
  .overlayDate .month {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px; }
  .overlayDate .day {
    font-size: 15px;
    line-height: 18px; }

.overlayTitle {
  width: 362px;
  margin-right: 117px; }

@media (max-width: 1028px) {
  .scheduleContent .wrapper {
    width: 41vw; }
  .scheduleList {
    margin-left: 34px; }
  .scheduleContent .moreLink {
    margin-left: 34px; } }

@media (max-width: 991px) {
  .scheduleSection {
    flex-direction: column; }
  .scheduleContent,
  .schedulePic {
    width: 100%; }
  .schedDetail {
    width: auto; }
  .scheduleContent p {
    margin-bottom: 10px; }
  .scheduleContent .wrapper {
    width: 90%; }
  .scheduleContent .moreLink {
    margin: 0; }
  .scheduleList {
    margin-left: 0; }
  .scheduleItem {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 0; }
  .scheduleDate {
    font-size: 20px;
    line-height: 20px; }
  .location {
    font-size: 17px;
    line-height: 17px; }
  .title {
    font-size: 30px;
    line-height: 30px; }
  .scheduleItem a.blueLine {
    font-size: 20px; }
    .scheduleItem a.blueLine:after {
      left: 15px; }
  .schedulePic {
    height: 80vh; }
  .scheduleOverlay h2.blueLine {
    font-size: 23px; }
    .scheduleOverlay h2.blueLine:after {
      left: 187px;
      height: 10px; }
  .closeOverlay {
    font-size: 1px;
    color: white; }
    .closeOverlay:hover {
      color: white; }
  .overlayItem {
    background-position: center;
    padding: 64px 0; }
    .overlayItem .wrapper {
      flex-direction: column;
      padding: 0; }
    .overlayItem p {
      margin-bottom: 15px; }
    .overlayItem a.blueLine {
      font-size: 18px; }
      .overlayItem a.blueLine:after {
        left: 21px; }
  .overlayDate {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .overlayDate .month,
    .overlayDate .day {
      font-size: 18px;
      line-height: 21px; }
  .overlayTitle {
    width: 100%;
    margin: 0;
    text-align: center; }
  p.month {
    margin-bottom: 0; }
  .closeX {
    margin-top: 0px;
    margin-left: -53px; }
    .closeX .line {
      width: 37px;
      height: 5px; }
  .scheduleOverlay .closeX {
    margin-top: 15px; } }

.newsSection {
  background-color: #F5F4F4; }
  .newsSection h2.blueLine {
    z-index: 3; }
  .newsSection .wrapper {
    position: relative; }

.newsWord {
  position: relative; }
  .newsWord p {
    position: absolute;
    color: #E7EBED;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 1;
    margin: 0;
    font-size: 17vw;
    letter-spacing: -18px;
    top: -87px;
    line-height: 1;
    left: -13px; }

.newsContent {
  position: relative;
  z-index: 3;
  margin-left: 81px;
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.morePress {
  margin: 0 auto; }

.newsItem {
  display: flex;
  margin-bottom: 76px;
  min-width: 840px;
  max-width: 950px; }

.hideNews {
  display: none; }

.newsPic {
  width: 50%;
  background-color: white;
  padding-bottom: 50%;
  position: relative; }
  .newsPic img {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.newsPic.picSmall1 img {
  top: auto;
  bottom: 25%; }

.newsPic.picSmall2 img {
  top: auto;
  bottom: 15%; }

.newsText {
  width: 50%;
  background-color: white;
  padding: 30px 42px 26px 42px; }

.newsIntro {
  font-size: 18px;
  line-height: 23px; }

.newsDate {
  display: flex; }
  .newsDate p {
    margin: 0;
    font-size: 11px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase; }
    .newsDate p:first-of-type {
      padding-right: 5px;
      border-right: solid #586162 2px; }
    .newsDate p:nth-of-type(2) {
      padding-left: 5px; }

.blue {
  color: #6DEAEA; }

.newsTitle {
  font-size: 21px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 21px; }

@media (max-width: 1000px) {
  .newsContent {
    margin-left: 0; } }

@media (max-width: 920px) {
  .newsContent {
    align-items: center; }
  .newsItem {
    flex-direction: column;
    align-items: center; } }

@media (max-width: 760px) {
  .newsDate p {
    font-size: 18px; }
  .newsDate p:first-of-type {
    padding-right: 9px; }
  .newsDate p:nth-of-type(2) {
    padding-left: 9px; }
  .newsTitle {
    font-size: 29px;
    line-height: 34px; }
  .newsIntro {
    font-size: 21px;
    line-height: 33px; }
  .newsPic {
    padding-bottom: 0; }
    .newsPic img {
      position: relative; }
  .newsText,
  .newsPic {
    width: 66%; } }

@media (max-width: 728px) {
  .newsWord p {
    font-size: 28vw;
    top: -138px; } }

@media (max-width: 600px) {
  .newsSection .wrapper {
    width: 100%; }
  .newsSection h2 {
    margin-left: 5vw; }
  .newsPic,
  .newsText {
    width: 100%; }
  .newsContent {
    align-items: flex-start; }
  .morePress {
    position: relative;
    left: 5vw; }
  .newsText {
    padding: 75px 5%;
    background-color: #F5F4F4; }
  .newsItem {
    min-width: auto;
    max-width: auto;
    margin-bottom: 0; }
  .morePress {
    margin: 0; } }

@media (max-width: 400px) {
  .newsWord p {
    letter-spacing: 1px; } }

section.pressSection {
  padding: 67px 0;
  position: relative; }
  section.pressSection h2.blueLine {
    position: relative;
    z-index: 3;
    margin-bottom: 0; }

.latestWord {
  position: relative; }
  .latestWord p {
    position: absolute;
    color: #EFF6F8;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 1;
    margin: 0;
    font-size: 11vw;
    letter-spacing: -5px;
    top: -87px;
    line-height: 1;
    left: 20px; }

.pressText {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center; }

.pressQuote {
  font-family: "Merriweather";
  font-weight: 700;
  font-size: 16px;
  margin-left: 115px;
  margin-top: 0; }
  .pressQuote p {
    margin-top: 0; }

.pressSource {
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0;
  min-width: 117px;
  text-transform: uppercase; }

.pressSource.opera {
  min-width: 147px; }

.quotationMark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .quotationMark img {
    width: 100%; }

@media (max-width: 900px) {
  .pressText {
    flex-wrap: wrap; }
  .pressQuote {
    width: 100%;
    margin-left: 0;
    margin-top: 30px; }
  .pressSource {
    font-size: 16px; }
  .quotationMark {
    height: 34px;
    width: 42px;
    margin-left: 12px; }
    .quotationMark p {
      font-size: 32px;
      top: 11px; } }

@media (max-width: 700px) {
  .pressQuote {
    margin-top: 63px;
    font-size: 22px; }
  .makeWhite {
    color: white; }
  .latestWord p {
    font-size: 29vw; } }

section.audioSection {
  position: relative;
  background-color: #905775;
  color: white; }
  section.audioSection h2.blueLine {
    position: relative;
    z-index: 3;
    color: white; }
    section.audioSection h2.blueLine:after {
      background-color: white; }

.bigAudio {
  position: relative; }
  .bigAudio p {
    position: absolute;
    color: #a57991;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 1;
    margin: 0;
    font-size: 11vw;
    letter-spacing: -5px;
    top: -100px;
    line-height: 1;
    left: 20px; }

.audioContent {
  position: relative;
  z-index: 3;
  display: flex;
  margin-left: 96px;
  margin-top: 33px; }

.audioText {
  width: calc(55% - 80px);
  margin-right: 80px;
  min-width: 361px; }
  .audioText p {
    margin: 0; }

p.ayre {
  font-size: 35px;
  line-height: 35px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 12px; }

p.role {
  margin-left: 10px; }

.audioArtist {
  display: flex; }
  .audioArtist p {
    font-weight: 700;
    font-size: 20px; }

p.ayreParagraph {
  margin: 25px 0 30px 0; }

p.quoteAyre {
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 0; }

.ayreAuthor {
  font-weight: 700;
  font-size: 14px; }

.ayreBold {
  font-weight: 400; }

.ayreList {
  display: flex; }
  .ayreList li {
    margin-right: 20px; }

.mobileAudioPic {
  display: none; }

.ayreLink {
  font-weight: 700;
  color: white;
  font-size: 17px;
  text-transform: uppercase; }

.audioPic {
  width: 45%; }
  .audioPic img {
    width: 100%;
    box-shadow: 20px -20px #6DEAEA; }

@media (max-width: 1310px) {
  .ayreLink {
    font-size: 13px; } }

@media (max-width: 990px) {
  .mobileAudioPic {
    display: block;
    margin-top: 50px;
    width: 100%; }
  .desktopAudioPic {
    display: none; }
  .audioText {
    margin: 0;
    width: 70%; }
  .audioContent {
    margin-left: 0; }
  .ayreLink {
    font-size: 16px; } }

@media (max-width: 810px) {
  .audioText {
    width: 80%; } }

@media (max-width: 760px) {
  .audioText {
    width: 100%; }
  .ayreList {
    flex-direction: column;
    margin-top: 32px; }
    .ayreList li {
      margin-bottom: 22px; }
  .ayreLink {
    font-size: 17px;
    font-weight: 900; }
  .bigAudio p {
    font-size: 36vw;
    letter-spacing: -19px;
    top: -186px;
    line-height: 1;
    left: -36px; } }

section.videoSection {
  position: relative;
  background-color: #6DEAEA;
  color: white; }
  section.videoSection h2.blueLine {
    position: relative;
    z-index: 3;
    color: white; }
    section.videoSection h2.blueLine:after {
      left: 39px;
      background-color: white; }

.whiteSquare {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
  left: 50%;
  background-color: #F5F4F4;
  z-index: 1; }

.bigVideo {
  position: relative; }
  .bigVideo p {
    position: absolute;
    color: #84f5f5;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 1;
    margin: 0;
    font-size: 11vw;
    letter-spacing: -5px;
    top: -100px;
    line-height: 1;
    left: 20px; }

.videoContainer {
  width: 100%;
  position: relative;
  z-index: 7;
  margin-top: 100px; }

.videoImage {
  width: 63%;
  margin: 0 auto;
  position: relative;
  transition: all 0.5s; }
  .videoImage img {
    width: 100%; }

.videoInfo {
  position: absolute;
  font-size: 16px;
  color: white;
  font-weight: 700;
  top: 30px;
  left: 38px;
  width: 300px;
  margin: 0;
  line-height: 23px; }

.playButton {
  position: absolute;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: white;
  bottom: 42px;
  left: 38px;
  transition: all 0.5s; }
  .playButton:hover {
    background-color: #6DEAEA; }
  .playButton a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10; }

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17px 0 17px 30.8px;
  border-color: transparent transparent transparent #000000;
  left: 23px;
  top: 18px;
  position: absolute;
  z-index: 3; }

.videoControls {
  position: absolute;
  right: 0;
  right: -15px;
  top: 41%; }

.videoNext,
.videoPrevious {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #6DEAEA;
  transition: all 0.2s; }
  .videoNext:hover,
  .videoPrevious:hover {
    background-color: #1F4852; }
  .videoNext:focus,
  .videoPrevious:focus {
    background-color: #6DEAEA; }
  .videoNext img,
  .videoPrevious img {
    position: absolute;
    width: 15px; }

.videoNext img {
  top: 7.5px;
  left: 8px; }

.videoPrevious {
  margin-top: 7px; }
  .videoPrevious img {
    transform: rotate(180deg);
    top: 9px;
    left: 7px; }

.videoPreviousLink,
.videoNextLink {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

@media (max-width: 750px) {
  .bigVideo {
    display: none; } }

@media (max-width: 590px) {
  .videoImage {
    width: 100%; }
  .videoInfo {
    font-size: 16px;
    line-height: 23px; } }

@media (max-width: 480px) {
  img.videoThumb {
    width: 166%;
    position: relative;
    right: 42%; }
  .videoControls {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    right: auto;
    width: 100%;
    left: auto;
    bottom: -53px;
    top: auto; }
  .videoNext,
  .videoPrevious {
    margin-top: 0;
    height: 50px;
    width: 50px;
    background-color: #1F4852; }
    .videoNext img,
    .videoPrevious img {
      width: 25px;
      top: 13.5px; }
    .videoNext:hover,
    .videoPrevious:hover {
      background-color: #1F4852; }
    .videoNext:focus,
    .videoPrevious:focus {
      background-color: #1F4852; }
  .videoNext {
    margin-left: 10px; }
  .videoNext img {
    left: 12px; }
  .videoPrevious img {
    left: 15px; }
  .videoInfo {
    font-size: 19px; } }

section.gallerySection {
  padding: 0;
  position: relative;
  background-color: #F5F4F4;
  display: flex; }
  section.gallerySection h2.blueLine {
    position: relative;
    z-index: 3;
    margin-bottom: 0; }
    section.gallerySection h2.blueLine:after {
      left: 78px; }

.imagesWord {
  position: relative; }
  .imagesWord p {
    position: absolute;
    color: #E7EBED;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 1;
    margin: 0;
    font-size: 11vw;
    letter-spacing: -5px;
    top: -87px;
    line-height: 1;
    left: 20px; }

.galleryTitle {
  width: 60%; }
  .galleryTitle .wrapper {
    width: 41vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 120px 0; }

.galleryThumbs {
  width: 40%; }

.thumb {
  position: relative; }
  .thumb p.blueLine {
    color: white;
    display: inline-block;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 800;
    position: absolute;
    margin: 0;
    bottom: 43px;
    left: 57px;
    line-height: 28px;
    z-index: 2;
    transition: all 0.5s; }
    .thumb p.blueLine:after {
      background-color: white;
      width: 44px;
      height: 6px;
      bottom: -7px;
      right: 0;
      left: auto; }
  .thumb img {
    width: 100%;
    display: block;
    position: relative;
    z-index: 2; }
  .thumb a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10; }
    .thumb a:hover + p {
      color: #6DEAEA; }
    .thumb a:hover + p.blueLine:after {
      background-color: #6DEAEA; }

.galleryOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 800;
  opacity: 0;
  overflow: scroll;
  background-color: white;
  padding: 60px 0 0 0;
  transition: all 0.5s;
  height: 100vh; }

.showOverlay {
  display: block;
  opacity: 1; }

.hideGallery {
  display: none; }

.topBarGallery {
  display: flex;
  justify-content: space-between; }
  .topBarGallery h2.blueLine {
    margin: 0; }
    .topBarGallery h2.blueLine:after {
      left: 92px; }

.galleryBottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 56px; }

.galleryButtons:hover svg {
  fill: #6DEAEA; }

.galleryArrow {
  width: 20px;
  fill: #586162; }
  .galleryArrow svg {
    width: 100%;
    transition: all 0.5s; }

.galleryLeft svg {
  transform: rotate(180deg); }

.photoCredits {
  font-weight: 700;
  font-style: 16px;
  text-align: center;
  margin-bottom: 0; }

.portraitGalleryList img,
.onstageGalleryList img {
  display: none;
  transition: all 0.3s; }

img.showImage {
  display: block;
  height: 58vh;
  opacity: 0; }

img.showImage.bigImage {
  height: 54.5vh; }

@media (max-width: 940px) {
  .galleryTitle {
    width: 50%; }
  .galleryThumbs {
    width: 50%; } }

@media (max-width: 840px) {
  .showImage img {
    height: 45vh; }
  img.showImage.bigImage {
    height: 44.5vh; } }

@media (max-width: 810px) {
  .galleryOverlay .wrapper {
    height: 100%; }
  .galleryButtons {
    display: none; }
  img.showImage.bigImage {
    height: auto; }
  img.showImage {
    height: auto;
    width: 80%;
    margin: 0 auto; } }

@media (max-width: 760px) {
  .closeOnstage {
    margin: 23px 0 0; } }

@media (max-width: 650px) {
  .gallerySection {
    flex-direction: column; }
  .topBarGallery h2.blueLine {
    font-size: 27px; }
    .topBarGallery h2.blueLine:after {
      height: 12px;
      right: 0;
      left: auto; }
  .galleryTitle {
    width: 100%; }
    .galleryTitle .wrapper {
      width: 90%; }
  .galleryThumbs {
    width: 100%; }
  .imagesWord p {
    font-size: 27vw; }
  .thumb p.blueLine {
    font-size: 36px; }
    .thumb p.blueLine:after {
      width: 68px;
      height: 9px;
      bottom: -13px; } }

.contactSection h2.blueLine {
  z-index: 3; }
  .contactSection h2.blueLine:after {
    left: 87px;
    z-index: 3; }

.contactSection .wrapper {
  position: relative; }

.connectWord {
  position: relative; }
  .connectWord p {
    position: absolute;
    color: #EFF6F8;
    text-transform: uppercase;
    font-weight: 900;
    z-index: 1;
    margin: 0;
    font-size: 12vw;
    letter-spacing: -9px;
    top: -104px;
    line-height: 1;
    left: -13px; }

.contactInfo {
  margin-top: 67px;
  position: relative;
  z-index: 10; }
  .contactInfo p {
    margin: 0; }

p.worldWide {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px; }

.dean {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400; }

.contactBlock {
  margin-top: 25px; }
  .contactBlock p {
    font-weight: 400;
    line-height: 22px; }
  .contactBlock .blue {
    font-weight: 700;
    text-transform: uppercase; }

.socialList {
  display: flex;
  margin-top: 10px; }

.socialItem {
  margin-right: 17px; }

.socialLink {
  color: #586162;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px; }

footer {
  background-color: #586162;
  padding: 23px 0; }
  footer p, footer a {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    margin: 0; }

@media (max-width: 850px) {
  .connectWord p {
    font-size: 19vw;
    letter-spacing: -9px;
    top: -137px; } }

@media (max-width: 760px) {
  .contactBlock p {
    line-height: 27px; }
  .socialList {
    flex-direction: column; }
  .socialItem {
    margin-bottom: 15px; }
  .socialLink {
    font-size: 20px;
    font-weight: 900; } }

@media (max-width: 620px) {
  footer p {
    font-size: 14px; }
  footer a.blueLine {
    min-width: 159px;
    font-size: 14px;
    display: inline-block; } }

@media (max-width: 580px) {
  .connectWord p {
    font-size: 30vw; } }
