.bioSection {
    background-color: $newsBack;

}

.bioContent {
    display: flex;
}

// left column contente

.bioQuote {
    width: calc(50% - 75px);
    margin-right: 75px;
}

.bioWord {
    position: relative;
    p {
        color: white; 
        text-transform: uppercase;
        font-weight: 900;
        position: absolute;
        z-index: 2;
        font-size: 200px;
        margin: 0;
        font-size: 22vw;
        letter-spacing: -18px;
        top: -60px;
        line-height: 1;
    }
}

.bioIntro {
    position: relative;
    z-index: 4;
    font-family: $headerFont;
    margin-left: 63px; 
    p {
        font-weight: 700;
        font-size: 17px;
    }
}

// right column content

.bioText {
    width: calc(50% - 75px);
    margin-left: 75px;
    position: relative; 
    z-index: 9;
}

.showBio {
    display: none;
}


// MEDIA

@media (max-width: 900px) {
    .bioQuote {
        margin-right: 30px;
        width: calc(50% - 30px);
    }

    .bioText {
        margin-left: 30px;
        width: calc(50% - 30px);
    }
}

@media (max-width: 730px) {
    .bioContent {
        flex-direction: column;
    }

    .bioQuote,
    .bioText {
        margin: 0;
        width: 100%;
    }

    .bioText {
        margin-top: 61px;
    }
    
    .bioIntro {
        margin: 0;
        p {
            font-size: 20px;
        }
    }

    .bioWord p {
        left: -10%;
        font-size: 42vw;
    }
}

@media (max-width: 550px) {
    .bioWord p {
        font-size: 62vw;
    }
}