.newsSection {
    background-color: $newsBack;
    h2.blueLine {
        z-index: 3;
    }
    .wrapper {
        position: relative;
    }
}

.newsWord {
    position: relative;
    p {
        position: absolute;
        color: $newsWord;
        text-transform: uppercase;
        font-weight: 900;
        z-index: 1;
        margin: 0;
        font-size: 17vw;
        letter-spacing: -18px;
        top: -87px;
        line-height: 1;
        left: -13px;
    }
} 

.newsContent {
    position: relative;
    z-index: 3;
    margin-left: 81px;
    margin-top: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.morePress {
    margin: 0 auto;
}



// individual news items

.newsItem {
    display: flex;
    margin-bottom: 76px;
    min-width: 840px;
    max-width: 950px;
}

.hideNews {
    display: none;
}

.newsPic {
    width: 50%;
    background-color: white;
    padding-bottom: 50%;
    position: relative;
    img {
        width: 100%;
        display: block;
        @include position(absolute, 0, 0, 0, 0);
    }
}

.newsPic.picSmall1 img {
    top: auto;
    bottom: 25%;
}

.newsPic.picSmall2 img {
    top: auto;
    bottom: 15%;
}

.newsText {
    width: 50%;
    background-color: white;
    padding: 30px 42px 26px 42px;
}

.newsIntro {
    font-size: 18px;
    line-height: 23px;
}

.newsDate {
    display: flex;
    p {
        margin: 0;
        font-size: 11px;
        line-height: 20px;
        font-weight: 700;
        text-transform: uppercase;
        &:first-of-type {
            padding-right: 5px;
            border-right: solid $textColor 2px;
        }
        &:nth-of-type(2) {
            padding-left: 5px;
        }
    }
}

.blue {
    color: $blue;
}

.newsTitle {
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 21px;
}


// MEDIA

@media (max-width: 1000px) {
    .newsContent {
        margin-left: 0;
    }
}

@media (max-width: 920px) {

    .newsContent {
        align-items: center;
    }
    .newsItem {
        flex-direction: column;
        align-items: center;
    }

    
}

@media (max-width: 760px) {
    .newsDate {
        p {
            font-size: 18px
        }
        p:first-of-type {
            padding-right: 9px; 
        }
        p:nth-of-type(2) {
            padding-left: 9px;
        }
    } 

    .newsTitle {
        font-size: 29px;
        line-height: 34px;

    }

    .newsIntro {
        font-size: 21px;
        line-height: 33px;
    }
    .newsPic {
        padding-bottom: 0;
        img {
            position: relative;
        }
    }

    .newsText,
    .newsPic {
        width: 66%;
    }
}

@media (max-width: 728px) {
    .newsWord p {
        font-size: 28vw;
        top: -138px;
    }
}

@media (max-width: 600px) {
    .newsSection {
        .wrapper {
            width: 100%;
        }
        h2 {
            margin-left: 5vw;
        }
    }

    .newsPic,
    .newsText {
        width: 100%;
    }

    

    .newsContent {
        align-items: flex-start;
    }

    .morePress {
        position: relative;
        left: 5vw;
    }

    .newsText {
        padding: 75px 5%;
        background-color: $newsBack;
    }

    .newsItem {
        min-width: auto;
        max-width: auto;
        margin-bottom: 0;
    }



    .morePress {
        margin: 0;
    }
  
}

@media (max-width: 400px) {
    .newsWord p {
        letter-spacing: 1px;
    }
}
