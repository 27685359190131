section.pressSection {
    padding: 67px 0;
    position: relative;
    h2.blueLine {
        position: relative;
        z-index: 3;
        margin-bottom: 0;
    }
}

.latestWord {
    position: relative;
    p {
        position: absolute;
        color: $latestConnect;
        text-transform: uppercase;
        font-weight: 900;
        z-index: 1;
        margin: 0;
        font-size: 11vw;
        letter-spacing: -5px;
        top: -87px;
        line-height: 1;
        left: 20px;
    }
}

.pressText {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
}

.pressQuote {
    font-family: $headerFont;
    font-weight: 700;
    font-size: 16px;
    margin-left: 115px;
    margin-top: 0;
    p {
        margin-top: 0;
    }
}

.pressSource {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0;
    min-width: 117px;
    text-transform: uppercase;
}

.pressSource.opera {
    min-width: 147px;
}

.quotationMark {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
        width: 100%;
    }
}



// MEDIA   QUERIES

@media (max-width: 900px) {
    .pressText {
        flex-wrap: wrap;

    }

    .pressQuote {
        width: 100%;
        margin-left: 0;
        margin-top: 30px;
    }

    .pressSource {
        font-size: 16px;
    }

    .quotationMark {
        height: 34px;
        width: 42px;
        margin-left: 12px;
        p {
            font-size: 32px;
            top: 11px;
        }
    }
}

@media (max-width: 700px) {
    .pressQuote {
        margin-top: 63px;
        font-size: 22px;
    }

    .makeWhite {
        color: white;
    }

    .latestWord p {
        font-size: 29vw;
    }
}