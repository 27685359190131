.scheduleSection {
    color: white;
    display: flex;
    padding: 0;
}

// left column

.scheduleList {
    margin-left: 71px;
    margin-bottom: 50px;
}

.scheduleContent {
    padding: 120px 0;
    width: 50%;
    background-image: url('../assets/sched/sched1small.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    p {
        margin: 0;
    }
    .moreLink {
        margin-left: 71px;
        color: white;
    }
    .wrapper {
        width: 39vw;

    }
}

.scheduleItem {
    display: flex;
    align-items: center;
    padding: 18px 0 10px 0;
    border-bottom: 1px solid white;
    &:last-of-type {
        border: none;
    }
    a.blueLine {
        color: white;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 700;
        &:after {
            left: 13px;
            width: 50%;
        }
    }
}

.schedDate {
    width: 131px;
}

.scheduleContent p.scheduleDate {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    margin-top: 4px;
}

.schedDetail {
    margin-right: 0px;
    width: 222px;
}

.title {
    font-size: 20px;
    font-style: italic;
}

.location {
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    text-transform: uppercase;
}

.onlineP {
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;
    text-transform: uppercase;
    display: block;
}



// right column

.schedulePic {
    padding: 120px 0;
    width: 50%;
    background-image: url('../assets/sched/sched1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: right;
    img {
        display: none;
    }
}

// schedule overlay

.scheduleOverlay {
    position: fixed; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 800;
    opacity: 0;
    overflow: scroll;
    background-color: white;
    padding: 60px 0;
    transition: all 0.5s;
    h2.blueLine:after {
        left: 262px;
    }
    .wrapperBig {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.showOverlay {
    display: block;
    opacity: 1;
}


.closeOverlay {
    display: flex;
    font-size: 17px;
    margin: -4px 0 0 0;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 200;
    color: $textColor;
    &:hover {
        color: $blue;

    }
    &:hover .line {
        background-color: $blue;
    }
}

.closeX {
    margin-top: 10px;
    margin-left: 10px;
    .line {
        width: 20px;
        height: 3px;
        background-color: $textColor;
        position: absolute;
        transition: all 0.5s;
    }
    .line1 {
        transform: rotate(45deg);
    }
    .line2 {
        transform: rotate(-45deg);
    }
}

.schedOverlayList {
    margin-top: 47px;
}

.overlayItem {
    padding: 31px 0px;
    transition: all 0.5s;
    &:hover {
        color: white;
        background-image: url('../assets/sched/sched1small.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        // background-position: center;
    }
    &:hover a.blueLine {
        color: white;
    }
    a.blueLine {
        color: $textColor;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 700;
        transition: all 0.5s;
        min-width: 96px;
        &:after {
            left: 16px;
            width: 50%;
        }
    }
    p {
        margin: 0;
    }
    .wrapper {
        display: flex;
        align-items: center;
        padding-right: 21vw;
    }
}

.overlayDate {
    min-width: 127px;
    margin-right: 95px;
    text-transform: uppercase;
    .month {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
    }
    .day {
        font-size: 15px;
        line-height: 18px;
    }
}

.overlayTitle {
    width: 362px;
    margin-right: 117px;
}


// MEDIA QUERIES

@media (max-width: 1028px) {
    .scheduleContent {
        .wrapper {
            width: 41vw;
        }
    }

    .scheduleList {
        margin-left: 34px;
    }

    .scheduleContent .moreLink {
        margin-left: 34px;
    }
}


@media (max-width: 991px) {
    .scheduleSection {
        flex-direction: column;
    }

    .scheduleContent,
    .schedulePic {
        width: 100%;
    }

    .schedDetail {
        width: auto;
    }

    .scheduleContent {
        p {
            margin-bottom: 10px;
        }
        .wrapper {
            width: 90%;
        }
        .moreLink {
            margin: 0;
        }
    }

    .scheduleList {
        margin-left: 0;
    }

    .scheduleItem {
        flex-direction: column;
        align-items: flex-start;
        padding: 40px 0;
    }

    .scheduleDate {
        font-size: 20px;
        line-height: 20px;
    }

    .location {
        font-size: 17px;
        line-height: 17px;
    }

    .title {
        font-size: 30px;
        line-height: 30px;
    }

    .scheduleItem a.blueLine {
        font-size: 20px;
        &:after {
            left: 15px;
        }
    }

    .schedulePic {
        // padding: 0;
        height: 80vh;

        // background-size: 100%;
        // background-image: none;
        // overflow: hidden;
    }

    // overlay

    .scheduleOverlay h2.blueLine {
        font-size: 23px;
        &:after {
            left: 187px;
            height: 10px;
        }
    }
    

    .closeOverlay{
        font-size: 1px;
        color: white;
        &:hover {
            color: white;
        }
    }

    .overlayItem {
        background-position: center;
        padding: 64px 0;
        .wrapper {
            flex-direction: column;
            padding: 0;
        }
        p {
            margin-bottom: 15px;
        }
        a.blueLine {
            font-size: 18px;
            &:after {
                left: 21px;
            }
        }
    }

    .overlayDate {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .month,
        .day {
            font-size: 18px;
            line-height: 21px;

        }
    }

    .overlayTitle {
        width: 100%; 
        margin: 0;
        text-align: center;
    }
    
    p.month {
        margin-bottom: 0;
    }

    .closeX {
        margin-top: 0px;
        margin-left: -53px;
        .line {
            width: 37px;
            height: 5px;
        }
    }

    .scheduleOverlay {
        .closeX {
            margin-top: 15px;
        }
    }

}

