.contactSection {
    h2.blueLine {
        z-index: 3;
        &:after {
            left: 87px;
            z-index: 3;
        }
    }
    .wrapper {
        position: relative;
    }
}

.connectWord {
    position: relative;
    p {
        position: absolute;
        color: $latestConnect;
        text-transform: uppercase;
        font-weight: 900;
        z-index: 1;
        margin: 0;
        font-size: 12vw;
        letter-spacing: -9px;
        top: -104px;
        line-height: 1;
        left: -13px;
    }
} 

.contactInfo {
    margin-top: 67px;
    position: relative;
    z-index: 10;
    p {
        margin: 0;
    }
}

p.worldWide {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
}

.dean {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
}

.contactBlock {
    margin-top: 25px;
    p {
        font-weight: 400;
        line-height: 22px;
    }
    .blue {
        font-weight: 700;
        text-transform: uppercase;
    }
}

.socialList {
    display: flex;
    margin-top: 10px;
}

.socialItem {
    margin-right: 17px;
}

.socialLink {
    color: $textColor;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
}



// FOOTER

footer {
    background-color: $footerBack;
    padding: 23px 0;
    p, a {
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        margin: 0;
    }
}


// MEDIA QUERIES

@media (max-width: 850px) {
    .connectWord p {
        font-size: 19vw;
        letter-spacing: -9px;
        top: -137px;
    }
}

@media (max-width: 760px) {
    .contactBlock p {
        line-height: 27px;
    }

    .socialList {
        flex-direction: column;
    }

    .socialItem {
        margin-bottom: 15px;
    }

    .socialLink {
        font-size: 20px;
        font-weight: 900;
    }


}


@media (max-width: 620px) {
    footer { 
        p {
            font-size: 14px;
        }
        a.blueLine {
            min-width: 159px;
            font-size: 14px;
            display: inline-block;
        }
    }
}


@media (max-width: 580px) {
    .connectWord p {
        font-size: 30vw;

    }
}