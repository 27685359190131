section.videoSection {
    position: relative;
    background-color: $blue;
    color: white;
    h2.blueLine {
        position: relative;
        z-index: 3;
        color: white;
        &:after {
            left: 39px;
            background-color: white;
        }
    }
}

.whiteSquare { 
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
    left: 50%;
    background-color: $newsBack;
    z-index: 1;
}

.bigVideo {
    position: relative;
    p {
        position: absolute;
        color: $videoBlue;
        text-transform: uppercase;
        font-weight: 900;
        z-index: 1;
        margin: 0;
        font-size: 11vw;
        letter-spacing: -5px;
        top: -100px;
        line-height: 1;
        left: 20px;
    }
}

.videoContainer {
    width: 100%;
    position: relative;
    z-index: 7;
    margin-top: 100px;
}

.videoImage {
    width: 63%;
    margin: 0 auto;
    position: relative;
    transition: all 0.5s;
    img {
        width: 100%;
    }
}

.videoInfo {
    position: absolute;
    font-size: 16px;
    color: white;
    font-weight: 700;
    top: 30px;
    left: 38px;
    width: 300px;
    margin: 0;
    line-height: 23px;
}

.playButton {
    position: absolute;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: white;
    bottom: 42px;
    left: 38px;
    transition: all 0.5s;
    &:hover {
        background-color: $blue;
    }
    a {
        @include position(absolute, 0, 0, 0, 0);
        z-index: 10;
    }
}

.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 0 17px 30.8px;
    border-color: transparent transparent transparent #000000;
    left: 23px;
    top: 18px;
    position: absolute;
    z-index: 3;
}

.videoControls {
    position: absolute;
    right: 0;
    right: -15px;
    top: 41%;
}

.videoNext,
.videoPrevious {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: $blue;
    transition: all 0.2s;
    &:hover {
        background-color: #1F4852;
    }
    &:focus {
        background-color: $blue;
    }
    img {
        position: absolute;
        width: 15px;
    }
}

.videoNext {
    img {
        top: 7.5px;
        left: 8px;
    }
}

.videoPrevious {
    margin-top: 7px;
    img {
        transform: rotate(180deg);
        top: 9px;
        left: 7px;
    }
}

.videoPreviousLink,
.videoNextLink {
    @include position(absolute, 0, 0, 0, 0);
}


// MEDIA QUERIES

@media (max-width: 750px) {
    .bigVideo {
        display: none;
    }

}

@media (max-width: 590px) {
    .videoImage {
        width: 100%;
    }

    .videoInfo {
        font-size: 16px;
        line-height: 23px;
    }
}

@media (max-width: 480px) {
  
    img.videoThumb {
        width: 166%;
        position: relative;
        right: 42%;
    }

    .videoControls {
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        right: auto;
        width: 100%;
        left: auto;
        bottom: -53px;
        top: auto;
    }

    .videoNext,
    .videoPrevious {
        margin-top: 0;
        height: 50px;
        width: 50px;
        background-color: #1F4852;
        img {
            width: 25px;
            top: 13.5px;
        }
        &:hover {
            background-color: #1F4852;
        }
        &:focus {
            background-color: #1F4852;
        }
    }

    .videoNext {
        margin-left: 10px;
    }

    .videoNext img {
        left: 12px;
    }

    .videoPrevious img {
        left: 15px;
    }

    .videoInfo {
        font-size: 19px;
    }
}

