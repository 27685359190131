section.gallerySection {
    padding: 0; 
    position: relative;
    background-color: $newsBack;
    display: flex;
    h2.blueLine {
        position: relative;
        z-index: 3;
        margin-bottom: 0;
        &:after {
            left: 78px;
        }
    }
}

.imagesWord {
    position: relative;
    p {
        position: absolute;
        color: $newsWord;
        text-transform: uppercase;
        font-weight: 900;
        z-index: 1;
        margin: 0;
        font-size: 11vw;
        letter-spacing: -5px;
        top: -87px;
        line-height: 1;
        left: 20px;
    }
}

.galleryTitle {
    width: 60%;
    .wrapper {
        width: 41vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 120px 0;
    }
}


.galleryThumbs {
    width: 40%;
}


.thumb {
    position: relative;
    p.blueLine {
        color: white;
        display: inline-block;
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 800;
        position: absolute;
        margin: 0;
        bottom: 43px;
        left: 57px;
        line-height: 28px;
        z-index: 2;
        transition: all 0.5s;
        &:after {
            background-color: white;
            width: 44px;
            height: 6px;
            bottom: -7px;
            right: 0;
            left: auto;
        }
    }
    img {
        width: 100%;
        display: block;
        position: relative;
        z-index: 2;
    }
    a {
        @include position(absolute, 0, 0, 0, 0);
        z-index: 10;
        &:hover + p {
            color: $blue;
        }
        &:hover + p.blueLine:after {
            background-color: $blue;;
        }
    }
}



// gallery Overlay 

.galleryOverlay {
    position: fixed; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 800;
    opacity: 0;
    overflow: scroll;
    background-color: white;
    padding: 60px 0 0 0;
    transition: all 0.5s;
    height: 100vh;
}

.showOverlay {
    display: block;
    opacity: 1;
}

.hideGallery {
    display: none;
}

.topBarGallery {
    display: flex;
    justify-content: space-between;
    h2.blueLine {
        margin: 0;
        &:after {
            left: 92px;
        }
    }
}

.galleryBottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 56px;
}

.galleryButtons {
    &:hover svg {
        fill: $blue;
    }
}


.galleryArrow {
    width: 20px;
    fill: $textColor;
    svg {
        width: 100%;
        transition: all 0.5s;
    }
}

.galleryLeft svg {
    transform: rotate(180deg);
}

.photoCredits {
    font-weight: 700;
    font-style: 16px;
    text-align: center;
    margin-bottom: 0;
}

.portraitGalleryList,
.onstageGalleryList {
    img {
        display: none;
        transition: all 0.3s;
    }
}

img.showImage {
    display: block;
    height: 58vh;
    opacity: 0;
}

img.showImage.bigImage {
    height: 54.5vh;
}

// MEDIA QUERIES

@media (max-width: 940px) {
    .galleryTitle {
        width: 50%;
    }

    .galleryThumbs {
        width: 50%;
    }
}

@media (max-width: 840px) {
    .showImage img {
        height: 45vh;
    }

    img.showImage.bigImage {
        height: 44.5vh;
    }
}

@media (max-width: 810px) {

    .galleryOverlay {
        .wrapper {
            height: 100%;
            
        }
    }

    // .galleryBottom {
    //     margin-top: 42%;
    // }
    
    .galleryButtons {
        display: none;
    }

    img.showImage.bigImage {
        height: auto;
    }

    img.showImage {
        height: auto;
        width: 80%;
        margin: 0 auto;
    }
}

@media (max-width: 760px) {
    .closeOnstage {
        margin: 23px 0 0;
    }
}

@media (max-width: 650px) {
    .gallerySection {
        flex-direction: column;
    }

    .topBarGallery h2.blueLine {
        font-size: 27px;
        &:after {
            height: 12px;
            right: 0;
            left: auto;
        }
    }

    .galleryTitle {
        width: 100%;
        .wrapper {
            width: 90%;
        }
    }

    .galleryThumbs {
        width: 100%;
    }

    .imagesWord p {
        font-size: 27vw;
    }

    .thumb p.blueLine {
        font-size: 36px;
        &:after {
            width: 68px;
            height: 9px;
            bottom: -13px;
        }
    }
}