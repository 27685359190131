
html {
    font-family: $bodyFont, "open sans", "sans serif";
    color: $textColor;
    
}

body {
    position: relative;
}

.no-js {
 visibility: hidden;
 opacity: 0;
}

.js {
 visibility: visible;
 opacity: 1;
}

.wrapper {
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    z-index: 20;
}

.ui-loader {
    display: none;
}

ul {
    padding: 0;
    list-style: none;
}

h2.blueLine {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 28px;
    display: inline-block;
    &:after {
        width: 44px;
        height: 8px;
        left: auto;
        right: 0;
    }
    &:hover:after {
        height: 8px;
    }
}

        

a {
    text-decoration: none;
    transition: all 0.5s;
    &:focus {
        outline: none;
    }
}

p {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

section {
    padding: 120px 0;
    overflow: hidden;
}

.italic {
    font-style: italic;
}

.hideOverlay {
    display: none;
}

.overflow {
    overflow: hidden;
}

// blue line that grows

.blueLine {
    position: relative;
    z-index: 2;
    &:after {
        content: '';
        display: inline-block;
        background-color: $blue;
        height: 4px;
        width: calc(100% + 2px);
        z-index: -1;
        transform-origin: center bottom;
        transition: all 0.2s ease-in-out 0s;
        position: absolute;
        bottom: -4px;
        left: 0;
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
        will-change: height;
    }
    &:hover:after {
        height: 15px;
    }
    img {
        height: 17px;
        transform: rotate(-90deg) translateX(-4px) translateY(10px);
        width: auto;
    }
}

.moreLink {
    font-size: 16px;
    text-transform: uppercase;
    color: $textColor;
    letter-spacing: 0.5px;
    font-weight: 700;
}



// fonts

.--light {
    font-weight: 300;
}


.semiBold {
    font-weight: 600;
}


.--bold {
    font-weight: 700;
}

.--extraBold {
    font-weight: 900;
}

// MEDIA

@media (max-width: 1028px) {
    .wrapper {
        width: 90%;
    }
}

@media (max-width: 760px) {
    
    section {
        padding: 100px 0;
    }

    p {
        font-size: 20px;
        line-height: 30px;
    }

    .blueLine {
        &:after {
            bottom: -8px;
            height: 8px;  
        }
        &:hover:after {
            height: 22px;
        }
    }

    h2.blueLine {
        font-size: 36px;
        &:after {
            width:64px;
            height: 15px;
            bottom: -10px;
        }
        &:hover:after {
            height: 15px;
        }
    }

    .moreLink.blueLine {
        font-size: 17px;
        font-weight: 900;
        &:after {
            bottom: -8px;
            height: 8px;
        }
        &:hover:after {
            height: 22px;
        }
    }
}