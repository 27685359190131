section.audioSection {
    position: relative;
    background-color: $purple;
    color: white;
    h2.blueLine {
        position: relative;
        z-index: 3;
        color: white;
        &:after {
            background-color: white;
        }
    }
}

.bigAudio {
    position: relative;
    p {
        position: absolute;
        color: $audioPurple;
        text-transform: uppercase;
        font-weight: 900;
        z-index: 1;
        margin: 0;
        font-size: 11vw;
        letter-spacing: -5px;
        top: -100px;
        line-height: 1;
        left: 20px;
    }
}

.audioContent {
    position: relative;
    z-index: 3;
    display: flex;
    margin-left: 96px;
    margin-top: 33px;
}

// left column

.audioText {
    width: calc(55% - 80px);
    margin-right: 80px;
    min-width: 361px;
    p {
        margin: 0;
    }
}

p.ayre {
    font-size: 35px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 12px;
}

p.role {
    margin-left: 10px;
}

.audioArtist {
    display: flex;
    p {
        font-weight: 700;
        font-size: 20px;
    }
}

p.ayreParagraph {
    margin: 25px 0 30px 0;
}

p.quoteAyre {
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 0;
}

.ayreAuthor {
    font-weight: 700;
    font-size: 14px;
}

.ayreBold {
    font-weight: 400;
}

.ayreList {
    display: flex;
    li {
        margin-right: 20px;
    }
}

.mobileAudioPic {
    display: none;
}


.ayreLink {
    font-weight: 700;
    color: white;
    font-size: 17px;
    text-transform: uppercase;
    
}

// left column

.audioPic {
    width: 45%;
    img {
        width: 100%;
        box-shadow: 20px -20px $blue;
    }
}

// MEDIA QUERIES

@media (max-width: 1310px) {
    .ayreLink {
        font-size: 13px;
    }
}

@media (max-width: 990px) {
    .mobileAudioPic {
        display: block;
        margin-top: 50px;
        width: 100%;
    }

    .desktopAudioPic {
        display: none;
    }

    .audioText {
        margin: 0;
        width: 70%;
    }

    .audioContent {
        margin-left: 0;
    }

    .ayreLink {
        font-size: 16px;
    }
}

@media (max-width: 810px) {
    .audioText {
        width: 80%;
    }
}

@media (max-width: 760px) {
    .audioText {
        width: 100%;
    }

    .ayreList {
        flex-direction: column;
        margin-top: 32px;
        li {
            margin-bottom: 22px;
        }
    }

    .ayreLink {
        font-size: 17px;
        font-weight: 900;
    }

    .bigAudio p {
        font-size: 36vw;
        letter-spacing: -19px;
        top: -186px;
        line-height: 1;
        left: -36px;
    }
}
