
header {
    height: 100vh;
    position: relative;
    will-change: background-image;
    background-image: url('../assets/header/header1small.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
    z-index: 300;
}

.headerMenu {
    height: 150px;
    padding: 30px 0;
    position: fixed; 
    z-index: 350;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.5s;
}


.menuDesktopScroll {
    background: -moz-linear-gradient(top, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.80) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.80) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
}

.hideHeaderMenu {
    transform: translateY(-150px);
}

.menuList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
}

.headerLogo {
    width: 212px;
}

.menuLink.blueLine {
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    &:after {
        width: 100%;
        left:0;
    }
}

// upcoming

.upCircle {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 0;
}

.upcomingHeader {

    background-color: white;
    padding: 8px 23px 15px 10vw;
}

.upcoming {
    color: $textColor;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    display: flex;
    img {
        width: 18px;
        transform: rotate(90deg) translateX(6px);
        margin-left: 5px;
    }
}

.upcoming.blueLine {
    &:after {
        width: calc(100% + 5px);
        left: -3px;
    }
}
 

// circles



.headerCircles {
    display: flex;
    justify-content: space-between;
    width: 92px;
    margin-left: 37px;
}

.circle {
    width: 15px;
    height: 15px;
    border: white solid 2px;
    border-radius: 50px;
    transition: all 0.5s;
}

.circleFill {
    background-color: white;
}

.mobileLogo {
    display: none;
}

.mobileButton {
    display: none;
}

.mobileMenu {
    display: none;
}


// MEDIA

@media (max-width: 1028px) {
    .upcomingHeader {
        padding: 8px 23px 15px 5vw;
    }

    .headerCircles {
        left: 233px;
    }
}



@media (max-width: 860px) {

    header {
        height: 85vh;
    }


    .headerMenu {
        position: fixed;
        z-index: 350;
        .menuList {
            display: none;
        }
    }

    .mobileLogo {
        display: block;
        position: relative;
        height: 100%;
        z-index: 10;
        .headerLogo {
            width: 365px;
            transform-origin: 100% 100%;
            position: absolute;
            transform: rotate(-90deg);
            bottom: 433px;
            right: 11vw;
        }
    }

    .mobileButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 20;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-top: 15px;
        padding-right: 11vw;
        p {
            color: white;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            margin: 0;
            margin-top: -7px;
            transition: all 0.3s;
        }
        &:hover .mobileLine {
            background-color: $blue;
        }

    }

    .mobileLine {
        width: 43px;
        height: 7px;
        border-radius: 50px;
        margin-bottom: 9px;
        background-color: white;
        transition: all 0.3s;
    }

    .headerCircles {
        margin-right: 10vw;
    }

    .upcomingHeader {
        padding: 13px 23px 23px 51px;
    }

    .upCircle {
        width: 100%;
        justify-content: space-between;
    }

    .upcoming.blueLine {
        font-size: 17px;
        font-weight: 900;
        &:after {
            bottom: -9px;
            height: 9px;
        }
        &:hover:after {
            height: 25px;
        }
        img {
            width: 12px;
            transform: rotate(90deg) translateX(5px);
        }
    }

    // mobile menu

    .mobileMenu {
        display: block;
        width: 328px;
        height: 100%;
        background-color: #00272C;
        position: fixed;
        right: 0;
        z-index: 499;
        padding: 27px;
        transform-origin: right center;
        transition: all 0.6s;
        transform: scaleX(0);
        overflow: scroll;
    }

    .openMobileMenu {
        transform: scaleX(1);
    }

    .menuCloseButton {
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        position: absolute;
        top: 57px;
        right: 30px;
        &:hover {
            color: $blue;
        }
        &:hover .menuX {
            background-color: $blue;
        }
    }   

    .menuX {
        width: 46px;
        height: 10px;
        border-radius: 50px;
        background-color: white;
        transition: all 0.3s;
    }

    .x1 {
        transform: rotate(45deg);
        margin-bottom: -10px;
    }

    .x2 {
        margin-bottom: 20px;
        transform: rotate(-45deg);
    }

    .mobileMenuList {
        margin-top: 60px;
        margin-bottom: 51px;
    }

    .mobileItem {
        margin-bottom: 18px;
    }

    a.mobileListLink.blueLine {
        font-size: 30px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        transition: all 0.3s;
        &:hover {
            color: $blue;
        }
        &:after {
            width: 34px;
            height: 9px;
        }
    }

    a.socialMobileLink.blueLine {
        font-size: 23px;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        transition: all 0.3s;
        &:hover {
            color: $blue;
        }
        &:after {
            background-color: $footerBack;
            width: 34px;
            height: 6px;
            bottom: -6px;
        }
    }
}



@media (max-width: 860px) and (max-height: 633px) {
    .mobileLogo {
        .headerLogo {
            width: 254px;
            bottom: 315px;
        }
    }
}

@media (max-width: 860px) and (max-height: 400px) {
    // .mobileMenu {
    //     width: 100%;
    // }

    // .mobileSocialList {
    //   display: flex;
    //   flex-wrap: wrap;
    // }

    // .mobileMenuList {
    //     display: flex;
    //     flex-wrap: wrap;
    // }

    // a.mobileListLink.blueLine {
    //     margin-right: 30px;
    // }

    .mobileLogo {
        .headerLogo {
            width: 254px;
            bottom: 315px;
            transform: none;
            top: 20px;
            bottom: auto;
            right: auto;
            left: 20px;
        }
    }
}

