

// COLORS
$textColor: #586162;
$blue: #6DEAEA;
$videoBlue: rgb(132, 245, 245); 
$newsBack: #F5F4F4;
$newsWord: #E7EBED;
$purple: #905775;
$audioPurple: #a57991;
$latestConnect: #EFF6F8;
$footerBack: #586162;

// MIXINS

@mixin position($type, $top, $right, $bottom, $left) {
    position: $type; 
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin display($type, $JC, $AI) {
    display: $type; 
    justify-content: $JC;
    align-items: $AI;
}


// FONTS

$headerFont: 'Merriweather';
$bodyFont: 'Open Sans';


@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-black-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-black-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Merriweather';
    src: url('../fonts/merriweather-regular-webfont.woff2') format('woff2'),
         url('../fonts/merriweather-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}


@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bolditalic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-extrabold-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/opensans-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}





